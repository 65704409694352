body {
    padding: 0px;
    margin: 0px;
}

* {
    box-sizing: border-box;
}

.AccentColor {
    background: #EFF3C6;
}

.FirstColor {
    background: #0779E4;
}

.SecondColor {
    background: #4CBBB9;
}

.ThirdColor {
    background: #77D8D8;
}

.Background {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    min-height: 100vh;
}

@media only screen and (max-width: 992px) {
    h1 {
        font-size: 6vw;
    }
    h2 {
        font-size: 4vw;
    }
}

.Button {
    height: 45px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ButtonText {
    text-align: center;
    line-height: 45px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
}

.Button:hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.ButtonText:hover {
    cursor: pointer;
}

.Button:active {
    filter: brightness(110%);
}

div[disabled] {
    pointer-events: none;
    opacity: 0.5;
}

.LoadingTitle {
    color: #0779E4;
    text-align: center;
}

.Panel {
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    /* min-height: 50vh;
    max-height: 70vh;
    min-width: 50%;
    max-width: 70%;
    padding: 5%; */
}

.Title {
    color: #0779E4;
    text-align: center;
    margin-bottom: 10%;
}

@media only screen and (max-width: 992px) {
    .Panel {
        min-height: 50vh;
        max-height: 70vh;
        min-width: 90%;
        max-width: 90%;
        padding: 5%;
    }
}

@media only screen and (min-width: 992px) {
    .Panel {
        min-height: 50vh;
        max-height: 70vh;
        min-width: 50%;
        max-width: 70%;
        padding: 5%;
    }
}