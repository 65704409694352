.Reservation {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-height: 100vh;
}

.ReservationInfo {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ReservationCode {
    flex: 1;
}

.PersonName {
    flex: 1;
    color: #0779e4;
}

.Details {
    flex: 1;
    color: #eff3c6;
}

.Location {
    flex: 1;
    padding: 1px;
    width: 100%;
    margin-bottom: 10%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    max-width: 70%;
}

.Download {
    min-width: 50%;
    max-width: 70%;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 992px) {
    .Download {
        min-width: 90%;
        max-width: 90%;
    }
}