.SelectedEventTitle {
    color: #0779e4;
}

.SelectedEventTime {
    color: white;
    margin-top: 1rem;
}

.SelectedEventTime h2 {
    margin: 0%;
}

.ButtonReserve {
    min-width: 70%;
    margin-top: 2rem;
}

.ButtonCancel {
    min-width: 70%;
    margin-top: 1rem;
}