.Menu {
    min-width: 70%;
}

.Manage, .Invitation {
    margin-top: 5%;
}

.ButtonText {
    text-align: center;
    line-height: 45px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}