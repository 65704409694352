.Login {
    /* max-height: 50vh; */
    /* min-width: 90vw; */
    /* min-width: 90%; */
}

.LoginTitle {
    color: #0779E4;
    text-align: center;
    margin-bottom: 1rem;
}

.LoginForm {
    min-width: 70%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.FormField {
    /* flex: 1 */
    padding: 2%;
    min-width: 100%;
    color: #0779E4;
    text-align: center;
    /* text-align: right; */
}

.FormField select, input {
    height: 45px;
    border: none;
    background: #EFF3C6;
    color: #0779E4;
    font-weight: bold;
    text-align: center;
    text-align-last: center;
    padding: 2%;
    margin-top: 2%;
    min-width: 100%;
}

.FormField select option {
    background: #0779E4;
}

.LoginButton {
    min-width: 100%;
    margin-top: 1rem;
}