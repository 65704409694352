.ErrorPanel {
    box-shadow: none;
    padding-top: 0%;
}

.ErrorMessage {
    text-align: center;
    color: #4cbbb9;
}

.ErrorButton {
    min-width: 50%;
    max-width: 70%;
    margin-top: 1rem;
}

.ErrorImage {
    min-width: 100%;
    max-width: 100%;
}