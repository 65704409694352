.EventsPanel {
    max-height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-bottom: 2%;
}

.Event {
    flex: 1;
    min-width: 100%;
    text-align: center;
    margin-bottom: 5%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 5%;
    font-weight: bold;
}

.EventTitle {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.EventBody {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 2.5%;
}

.EventDate {
    flex: 1;
    text-align: left;
}

.EventTime {
    flex: 1;
    text-align: right;
}

.EventName {
    flex: 1;
    text-align: right;
}

.EventCapacity {
    flex: 1;
    text-align: left;
}

